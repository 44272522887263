import { FunctionComponent } from 'react';
import Helmet from 'react-helmet';

const StructuredData: FunctionComponent<{ children: string }> = ({ children }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {children}
      </script>
    </Helmet>
  );
};

export default StructuredData;
