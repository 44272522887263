import { PageRowType } from '@@src/components/PageRows/PageRow';
import { generateFullUrlFromLinkProps, generateFullUrlFromPath } from '@@src/routes';
import ShelfStructuredData from '@@src/utils/StructuredData/ShelfStructuredData';
import ShortcutStructuredData from '@@src/utils/StructuredData/ShortcutStructuredData';
import OnDemand from '@@types/OnDemand';

export default class PageStructuredData {
  static generate(pageData: Partial<OnDemand.Page> | OnDemand.HeroItemPage, language: string) {
    const result = {
      '@context': 'https://schema.org/',
      '@type': 'CollectionPage',
      '@id': generateFullUrlFromLinkProps(pageData.route, language),
      isPartOf: {
        '@type': 'WebSite',
        name: 'SBS On Demand',
        url: generateFullUrlFromPath('/'),
      },
      name: pageData.title,
    };

    if ('description' in pageData) {
      result['description'] = pageData.description;
    }

    let filteredRows = [];

    if ('rows' in pageData && pageData.rows.length) {
      filteredRows = pageData.rows.filter((pageRow) => {
        return ('items' in pageRow && pageRow.items.length > 0) || ('collection' in pageRow && pageRow.collection.items.length);
      });
    }

    if (filteredRows.length) {
      result['hasPart'] = filteredRows.map((pageRow, index) => {
        return PageStructuredData.generateCollection(pageRow, language, index + 1);
      }).filter((part) => {
        return !!part.hasPart.length;
      });
    }

    return result;
  }

  static generateCollection(row: PageRowType, language: string, position) {
    let items = [];

    if (row.type === 'shortcuts') {
      items = row.items?.map((item, index) => {
        return ShortcutStructuredData.generate(item, language, index + 1);
      });
    }

    let filteredItems = [];

    if (row.type === 'carousel') {
      filteredItems = row.items.filter((item) => {
        return item.item.type !== 'Clip';
      });
    }

    if (row.type === 'grid' || row.type === 'shelf') {
      filteredItems = row.items.filter((item) => {
        return item.item.type !== 'Clip';
      });
    }

    if (filteredItems.length) {
      items = filteredItems.map((item, index) => {
        return ShelfStructuredData.generate(item, language, index + 1);
      });
    }

    // v2 shelves
    if (row.type === 'shelfV2' || row.type === 'gridV2' || row.type === 'listV2' || row.type === 'recommendation-shelf') {
      items = row.collection.items?.map((item, index) => {
        return ShelfStructuredData.generateV2(item, language, index + 1);
      });
    }

    const result = {
      '@type': 'Collection',
      name: 'collection' in row ? row.collection.title : row.name,
      hasPart: items,
      position,
    };

    if ('collection' in row && row.collection && 'route' in row.collection && row.collection.route) {
      result['@id'] = generateFullUrlFromLinkProps(row.collection.route, language);
    } else if ('route' in row && row.route) {
      result['@id'] = generateFullUrlFromLinkProps(row.route, language);
    }

    return result;
  }
}
