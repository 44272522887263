import OnDemand2 from '@@src/@types/OnDemand2';
import { ShortcutItem } from '@@src/components/Shelf/ShortcutShelf';
import { generateFullUrlFromPath } from '@@src/routes';

export default class ShortcutStructuredData {
  static generate(shortcutItem: ShortcutItem, language: string, position: number) {
    return {
      '@type': 'Collection',
      name: shortcutItem.name,
      url: generateFullUrlFromPath(shortcutItem.ondemandUri, language),
      position,
    };
  }
}
