import { Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import OdContainer from '@@src/components/Utils/OdContainer';
import useGetOpacityFromVerticalScroll from '@@src/hooks/useGetOpacityFromVerticalScroll';
import grey from '@@src/styles/colors/grey';
import OnDemand from '@@types/OnDemand';

import catalogOgImage from '../../static/images/catalog-og-image.png';
import Sponsorship from '../components/Advert/Sponsorship';
import AlternateLinks from '../components/Utils/AlternateLinks';
import UnfurlingMetaTags from '../components/Utils/UnfurlingMetaTags';
import { ensureFullUrl, generateFullUrlFromPath } from '../routes';
import fontFamily from '../styles/typography/fontFamily';

interface PageLayoutProps {
  seoTitle?: string;
  title?: string;
  titleLogo?: string;
  description?: string;
  metaDescription?: string;
  path: string;
  ogImage?: string;
  /**
   * if hero image is supplied, we will render the hero with title and description
   */
  heroImage?: OnDemand.Page['heroImage'];
  sponsorship?: OnDemand.Sponsorship;
}

const useStyles = makeStyles<Theme, PageLayoutProps>((theme: Theme) => {
  return createStyles({
    heroImage({ heroImage }) {
      let backgroundImage;

      if (heroImage && '16:9' in heroImage) {
        backgroundImage = `url(${heroImage['16:9']})`;
      }

      let backgroundImageMobile = backgroundImage;

      if (heroImage && '2:3' in heroImage) {
        backgroundImageMobile = `url(${heroImage['2:3']})`;
      }

      const startGradient = alpha(grey.darkBlackPearl, 0.3);
      const startGradientXs = alpha(grey.darkBlackPearl, 0.4);
      const midGradient = alpha(grey.darkBlackPearl, 0.8);
      const endGradient = grey.darkBlackPearl;

      return {
        minHeight: '56.25vw',
        marginBottom: 'calc(-56.25vw + 40px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',

        backgroundImage: `radial-gradient(circle at 50% -3.5%, ${startGradient} 0%, ${midGradient} 60%, ${endGradient} 80%), ${backgroundImage}`,

        [theme.breakpoints.down('xs')]: {
          minHeight: '150vw',
          marginBottom: 'calc(-150vw + 40px)',
          backgroundImage: `radial-gradient(circle at 50% 7.1%, ${startGradientXs} 0%, ${midGradient} 40%, ${endGradient} 60%), ${backgroundImageMobile}`,
        },
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: -1,
      };
    },
    heroContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 10,

      width: '100%',
      height: 'auto',
      textAlign: 'center',

      margin: '190px auto 150px auto',
      [theme.breakpoints.down('md')]: {
        marginTop: 164,
        marginBottom: 124,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 140,
        marginBottom: 100,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 104,
        marginBottom: 64,
      },
    },
    heading: {
      fontSize: '4.5rem',
      maxWidth: 1000,
      margin: '0 auto',
      padding: '0 32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '3.25rem',
        maxWidth: 900,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 800,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.125rem',
      },
    },
    headingLogo({ titleLogo }) {
      if (titleLogo) {
        return {
          backgroundImage: `url(${titleLogo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          textIndent: -9999,
          backgroundPosition: 'center',
          height: 169,
          [theme.breakpoints.down('lg')]: {
            height: 156,
          },
          [theme.breakpoints.down('md')]: {
            height: 140,
          },
          [theme.breakpoints.down('sm')]: {
            height: 100,
          },
          [theme.breakpoints.down('xs')]: {
            height: 88,
          },
        };
      }
      return {};
    },
    description: {
      margin: '32px auto 0',
      padding: '0 32px',
      fontSize: '1.125rem',
      fontWeight: 500,
      maxWidth: 800,
      [theme.breakpoints.down('md')]: {
        marginTop: 32,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        marginTop: 24,
        maxWidth: 700,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
        marginTop: 20,
      },
    },
    titleSecondary: {
      fontFamily: fontFamily.secondary,
    },
    titleUbuntu: {
      fontFamily: fontFamily.ubuntu,
    },
    sponsorship: {
      marginTop: 40,
      [theme.breakpoints.down('md')]: {
        marginTop: 32,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 24,
      },
    },
  });
});

const PageLayout: FunctionComponent<PageLayoutProps> = (props) => {
  const {
    seoTitle,
    title,
    description,
    metaDescription = description,
    path,
    children,
    heroImage,
    ogImage,
    sponsorship,
  } = props;
  const classes = useStyles(props);
  const heroImageOpacity = useGetOpacityFromVerticalScroll(0.2);

  const { i18n: { language } } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{seoTitle || title}</title>
        {
          metaDescription && (
            <meta name="description" content={metaDescription}/>
          )
        }
        <link href={generateFullUrlFromPath(path, language)} rel="canonical"/>
      </Helmet>
      <AlternateLinks/>
      <UnfurlingMetaTags
        title={seoTitle || title || 'SBS On Demand'}
        description={metaDescription}
        imageUrl={ogImage ? ensureFullUrl(ogImage) : ensureFullUrl(catalogOgImage)}
        url={generateFullUrlFromPath(path, language)}
      />
      {
        heroImage && (
          <>
            <div className={classes.heroImage} style={{ opacity: heroImageOpacity }}/>
            {
              title && (
                <div className={classes.heroContent}>
                  <OdContainer>
                    {
                      title && (
                        <Typography
                          variant="h1"
                          component="h1"
                          className={clsx(classes.heading, classes.headingLogo, language === 'ar' || language === 'hi' ? classes.titleSecondary : classes.titleUbuntu)}
                        >
                          {title}
                        </Typography>
                      )
                    }
                    {
                      description && (
                        <div className={classes.description}>{description}</div>
                      )
                    }
                  </OdContainer>
                  {sponsorship && (
                    <Sponsorship
                      type="stack"
                      sponsorship={sponsorship}
                      classes={{ root: classes.sponsorship }}
                    />
                  )}
                </div>
              )
            }
          </>
        )
      }
      {children}
    </div>
  );
};

export default PageLayout;
