import { getResizedUrl } from '@@src/components/Html/Image';
import { generateFullUrlFromLinkProps } from '@@src/routes';
import OnDemand from '@@types/OnDemand';
import OnDemand2 from '@@types/OnDemand2';

export default class MovieStructuredData {
  // eslint-disable-next-line max-len
  static generate(movie: Partial<OnDemand.Movie | OnDemand.OneOff> | OnDemand2.CollectionItemMovie | OnDemand2.CollectionItemProgram, language: string, position: number | undefined = undefined) {
    let imageId: string;
    if ('odImageId' in movie) {
      imageId = movie.odImageId;
    } else if ('imageId' in movie) {
      imageId = movie.imageId;
    }

    const result = {
      '@context': 'https://schema.org',
      '@type': 'Movie',
      url: generateFullUrlFromLinkProps(movie.route, language),
      name: movie.title,
      description: movie.description,

      datePublished: movie.airDate,
      thumbnailUrl: getResizedUrl({ imageId }, { width: 340, crop: false }),
      image: getResizedUrl({ imageId }, { width: 340, crop: false }),
      duration: movie.duration,
    };

    if (movie?.directors?.length) {
      result['director'] = movie.directors?.map((director) => {
        return {
          '@type': 'Person',
          name: director,
        };
      });
    }

    if (movie?.cast?.length) {
      result['actor'] = movie.cast.map((actor) => {
        return {
          '@type': 'Person',
          name: actor,
        };
      });
    }

    if (position) {
      result['position'] = position;
    }

    return result;
  }
}
