import OnDemand2 from '@@src/@types/OnDemand2';
import { generateFullUrlFromLinkProps, OdLinkProps } from '@@src/routes';
import EpisodeStructuredData from '@@src/utils/StructuredData/EpisodeStructuredData';
import OnDemand from '@@types/OnDemand';

export default class SeasonStructuredData {
  static generate(season: OnDemand.SeriesItemSeason & { route: OdLinkProps }, language: string) {
    return {
      '@type': 'TVSeason',
      seasonNumber: season.seasonNumber,
      url: `${generateFullUrlFromLinkProps(season.route, language)}/${season.seasonSlug}`,
      episode: season.episodes.map((episode) => {
        return EpisodeStructuredData.createSlimEpisode(episode);
      }),
    };
  }

  static generateV2(season: OnDemand2.CollectionItemSeason & { route: OdLinkProps }, language: string) {
    return {
      '@type': 'TVSeason',
      seasonNumber: season.seasonNumber,
      url: `${generateFullUrlFromLinkProps(season.route, language)}/${season.seasonSlug}`,
      episode: season.episodes.map((episode) => {
        return EpisodeStructuredData.createSlimEpisodeV2(episode);
      }),
    };
  }
}
