import OnDemand2 from '@@src/@types/OnDemand2';
import { getResizedUrl } from '@@src/components/Html/Image';
import { generateFullUrlFromLinkProps } from '@@src/routes';
import OnDemand from '@@types/OnDemand';

export default class EpisodeStructuredData {
  static generate(episode: Partial<OnDemand.Episode> | OnDemand2.CollectionItemEpisode, language: string, position: number | undefined = undefined) {
    let imageId: string;
    if ('odImageId' in episode) {
      imageId = episode.odImageId;
    } else if ('imageId' in episode) {
      imageId = episode.imageId;
    }

    const result = {
      '@context': 'https://schema.org',
      '@type': 'TVEpisode',
      url: generateFullUrlFromLinkProps(episode.route, language),
      name: episode.title,
      description: episode.description,

      datePublished: episode.airDate,
      thumbnailUrl: getResizedUrl({ imageId }, { width: 340, crop: false }),
      image: getResizedUrl({ imageId }, { width: 340, crop: false }),
      duration: episode.duration,
    };

    if (episode?.directors?.length) {
      result['director'] = episode.directors.map((director) => {
        return {
          '@type': 'Person',
          name: director,
        };
      });
    }

    if (episode?.cast?.length) {
      result['actor'] = episode.cast.map((actor) => {
        return {
          '@type': 'Person',
          name: actor,
        };
      });
    }

    if ('episodeData' in episode) {
      if (episode.episodeData?.seasonNumber) {
        result['partOfSeason'] = {
          '@type': 'TVSeason',
          seasonNumber: episode.episodeData.seasonNumber,
          name: episode.episodeData.programName,
          url: `${generateFullUrlFromLinkProps(episode.episodeData.seriesRoute, language)}/${episode.episodeData.seasonSlug}`,
        };
      }

      if (episode.episodeData?.episodeNumber) {
        result['episodeNumber'] = episode.episodeData.episodeNumber;
      }

      if (position) {
        result['position'] = position;
      }
    } else {
      if ('seasonNumber' in episode && episode.seasonNumber) {
        result['partOfSeason'] = {
          '@type': 'TVSeason',
          seasonNumber: episode.seasonNumber,
          name: episode.seriesTitle,
          url: `${generateFullUrlFromLinkProps(episode.route, language)}/${episode.seasonSlug}`,
        };
      }

      if ('episodeNumber' in episode && episode.episodeNumber) {
        result['episodeNumber'] = episode.episodeNumber;
      }

      if (position) {
        result['position'] = position;
      }
    }
    return result;
  }

  static createSlimEpisode(episode: OnDemand.SlimEpisode) {
    return {
      '@type': 'TVEpisode',
      name: episode.title,
      description: episode.description,
      url: generateFullUrlFromLinkProps(episode.route, 'en'),
      image: getResizedUrl({ imageId: episode.odImageId }, { width: 640, height: 360 }),
      contentRating: episode.classification,
      episodeNumber: episode.episodeNumber,
    };
  }

  static createSlimEpisodeV2(episode: OnDemand2.CollectionItemEpisode) {
    return {
      '@type': 'TVEpisode',
      name: episode.title,
      description: episode.description,
      url: generateFullUrlFromLinkProps(episode.route, 'en'),
      image: getResizedUrl({ imageId: episode.imageId }, { width: 640, height: 360 }),
      contentRating: episode.classificationId,
      episodeNumber: episode.episodeNumber,
    };
  }
}
