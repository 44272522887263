import OnDemand2 from '@@src/@types/OnDemand2';
import { PageRowType } from '@@src/components/PageRows/PageRow';
import { generateFullUrlFromLinkProps } from '@@src/routes';
import ShelfStructuredData from '@@src/utils/StructuredData/ShelfStructuredData';
import ShortcutStructuredData from '@@src/utils/StructuredData/ShortcutStructuredData';
import OnDemand from '@@types/OnDemand';

export default class CollectionStructuredData {
  // eslint-disable-next-line max-len
  static generate(row: PageRowType | OnDemand.HeroItemCollection | Partial<OnDemand.Collection> | OnDemand2.CollectionItemCollection, language: string, position: number | undefined = undefined) {
    const result = {
      '@type': 'Collection',
    };

    if ('name' in row) {
      result['name'] = row.name;
    } else if ('title' in row) {
      result['name'] = row.title;
    }

    if (row.type === 'shortcuts') {
      result['hasPart'] = row.items?.map((item, index) => {
        return ShortcutStructuredData.generate(item, language, index + 1);
      });
    }

    if (row.type === 'carousel' || row.type === 'grid' || row.type === 'shelf') {
      result['hasPart'] = row.items?.map((item, index) => {
        return ShelfStructuredData.generate(item, language, index + 1);
      });
    }

    if ('route' in row) {
      result['url'] = generateFullUrlFromLinkProps(row.route, language);
    }

    if (position) {
      result['position'] = position;
    }

    return result;
  }

  static generateV2(collection: OnDemand2.CollectionItemCollection, language: string, position: number | undefined = undefined) {
    const result = {
      '@type': 'Collection',
    };

    if ('title' in collection) {
      result['name'] = collection.title;
    }

    if (collection.type === 'gridV2' || collection.type === 'recommendation-shelf' || collection.type === 'shelfV2') {
      result['hasPart'] = collection.items?.map((item, index) => {
        return ShelfStructuredData.generateV2(item, language, index + 1);
      });
    }

    if (position) {
      result['position'] = position;
    }
  }
}
