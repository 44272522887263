import CollectionStructuredData from '@@src/utils/StructuredData/CollectionStructuredData';
import EpisodeStructuredData from '@@src/utils/StructuredData/EpisodeStructuredData';
import MovieStructuredData from '@@src/utils/StructuredData/MovieStructuredData';
import PageStructuredData from '@@src/utils/StructuredData/PageStructuredData';
import SeriesStructuredData from '@@src/utils/StructuredData/SeriesStructuredData';
import OnDemand from '@@types/OnDemand';
import OnDemand2 from '@@types/OnDemand2';

export default class ShelfStructuredData {
  static generate(shelfItem: OnDemand.Hero | OnDemand.Tile, language: string, position: number | undefined = undefined) {
    if (shelfItem.item.type === 'Episode') {
      return EpisodeStructuredData.generate(shelfItem.item, language, position);
    }

    if (shelfItem.item.type === 'Movie' || shelfItem.item.type === 'OneOff') {
      return MovieStructuredData.generate(shelfItem.item, language, position);
    }

    if (shelfItem.item.type === 'Page') {
      return PageStructuredData.generate(shelfItem.item, language);
    }

    if (shelfItem.item.type === 'TVSeries') {
      return SeriesStructuredData.generate(shelfItem.item, language);
    }

    if (shelfItem.item.type === 'Collection') {
      return CollectionStructuredData.generate(shelfItem.item, language);
    }

    if (shelfItem.item.type === 'Clip') {
      // ignore clips
    }

    return {};
  }

  static generateV2(shelfItem: OnDemand2.CollectionItem, language: string, position: number | undefined = undefined) {
    if (shelfItem.entityType === 'Movie' || shelfItem.entityType === 'Program') {
      return MovieStructuredData.generate(shelfItem, language, position);
    }

    if (shelfItem.entityType === 'Episode') {
      return EpisodeStructuredData.generate(shelfItem, language, position);
    }

    if (shelfItem.entityType === 'Series') {
      return SeriesStructuredData.generate(shelfItem, language);
    }

    if (shelfItem.entityType === 'Collection') {
      return CollectionStructuredData.generate(shelfItem, language);
    }

    return {};
  }
}
